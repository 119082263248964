import { css, Global, useTheme } from '@emotion/react';
import { commonThemeGlobals, generateGrid, commonScrollbar } from 'common-ui';
import emotionNormalize from 'emotion-normalize';
import { globals } from 'common-ui';

export const globalcss = (theme: ReturnType<typeof useTheme>) => {
  const gridClasses = generateGrid(theme);

  const globalStyles = css`
    * {
      box-sizing: border-box;
    }

    html.vine-react-app,
    body.vine-react-app {
      padding: 0;
      margin: 0;
      min-height: 100%;
      font-family: ${theme.fonts.familySecondary};
      font-size: ${theme.fonts.sizeRoot};
      ${commonScrollbar(theme)};
    }

    // * '.vine-react-app' is a class used in the '<body>' of a Next.js app and embedded apps roots (header and footer).
    .vine-react-app {
      //icon fonts generated with 'iconbuild'
      @font-face {
        font-family: 'icons';
        src:
          url('${globals.assetsPath}/next/fonts/icons.ttf?v=${globals.buildVersion}') format('truetype'),
          url('${globals.assetsPath}/next/fonts/icons.woff?v=${globals.buildVersion}') format('woff'),
          url('${globals.assetsPath}/next/fonts/icons.woff2?v=${globals.buildVersion}') format('woff2');
      }
      ${emotionNormalize}
      ${commonThemeGlobals}
      ${gridClasses}
      table {
        border-collapse: collapse;
      }

      // Utility Classes
      .h1,
      h1,
      .custom-h2-header {
        ${theme.mixins.h1}
      }
      .h2,
      h2 {
        ${theme.mixins.h2}
      }
      .h3,
      h3 {
        ${theme.mixins.h3}
      }
      .h4,
      h4 {
        ${theme.mixins.h4}
      }
      .hxl {
        ${theme.mixins.hxl}
      }
      .h5,
      h5 {
        ${theme.mixins.h5}
      }
      .h6,
      h6 {
        ${theme.mixins.h6}
      }
      .h7 {
        ${theme.mixins.h7}
      }
      .h8 {
        ${theme.mixins.h8}
      }
      .eyebrowRegular {
        ${theme.mixins.eyebrowRegular}
      }
      .eyebrowSmall {
        ${theme.mixins.eyebrowSmall}
      }
      .utility {
        ${theme.mixins.utility}
      }
      .quote,
      blockquote {
        ${theme.mixins.quote}
      }
      .caption {
        ${theme.mixins.caption}
      }
      .body1,
      p {
        ${theme.mixins.body1}
        &.strikeThrough {
          text-decoration: ${theme.fonts.caseStrikeThrough};
        }
        &.bold {
          font-weight: ${theme.fonts.weightBold};
        }
      }
      .body2 {
        ${theme.mixins.body2}
        &.strikeThrough {
          text-decoration: ${theme.fonts.caseStrikeThrough};
        }
        &.bold {
          font-weight: ${theme.fonts.weightBold};
        }
      }
      .bodySmall {
        ${theme.mixins.body3}
        &.strikeThrough {
          text-decoration: ${theme.fonts.caseStrikeThrough};
        }
        &.bold {
          font-weight: ${theme.fonts.weightBold};
        }
      }
      p {
        font-family: ${theme.fonts.familySecondary};
        font-size: ${theme.fonts.sizeDefault};
      }
      small {
        font-family: ${theme.fonts.familySecondary};
        font-size: ${theme.fonts.sizeSmall};
      }
      a {
        ${theme.mixins.linkPrimary}
      }
      a.secondary {
        ${theme.mixins.linkSecondary}
      }
      a.tertiary {
        ${theme.mixins.linkTertiary}
      }
      // OSANO
      .osano-cm-widget {
        display: none;
      }
      .LearnMore-modal {
        .mantine-Overlay-root {
          z-index: 600;
        }
        .mantine-Modal-body {
          height: 100% !important;
          width: 100% !important;
          .modalTitle {
            font-family: ${theme.fonts.familyPrimary};
            font-size: 30px;
            font-weight: 500;
            line-height: 33px;
            text-align: center;
            display: flex;
            margin: auto;
            justify-content: center;
            color: ${theme.colors.white};
            margin: 12px !important;
            @media (max-width: 480px) {
              font-size: 25px;
            }
          }
          .modalTitle2 {
            text-transform: none;
            margin-bottom: 25px !important;
            display: flex;
            margin: auto;
            justify-content: center;
            font-family: ${theme.fonts.familyTertiary};
            font-size: 18px;
            font-weight: 600px;
            line-height: 25.2px;
            text-align: center;
            color: ${theme.colors.white};
            @media (max-width: 480px) {
              font-size: 16px;
            }
          }
          .divider {
            margin-bottom: 36px;
          }
          .description {
            margin-bottom: 36px;
            font-family: ${theme.fonts.familySecondary};
          }
          .incentives {
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 400px;
            font-family: ${theme.fonts.familySecondary};
          }
        }
        background-color: ${theme.colors.unltPopupBackground};
        .mantine-Modal-inner {
          .mantine-Paper-root {
            max-width: 450px !important;
            @media (max-width: 480px) {
              max-width: 375px !important;
            }
          }
          z-index: 1000;
          .learnMore-header {
            margin-top: 10px;
            color: ${theme.colors.white};
            background-color: ${theme.colors.unltPopupBackground};
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: 100%;
            .unlimited-img {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
            .learnMore-closeBtn {
              margin-left: auto;
            }
          }
          .termsAndCondContent {
            height: 200px;
            overflow-y: scroll;
          }
          .join-unlimited {
            width: 191px;
            height: 44px;
            background-color: ${theme.colors.white};
            border-radius: 0px;
            color: black;
            .loadIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              .addingText {
                margin-left: 30px;
              }
            }
          }
          .termsAndCond {
            color: ${theme.colors.white};
            max-height: 200px;
            overflow-y: auto;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500px;
          }
          .termsAndCondContent {
            margin-top: 10px;
            font-size: 0.8rem;
          }

          .mantine-Paper-root {
            background-color: ${theme.colors.unltPopupBackground};
            .mantine-Text-root {
              color: ${theme.colors.white};
            }
          }
        }
      }
      .product-layout {
        .add-to-cart-section {
          width: 0px;
        }
        .cart-button .css-9iozqi-Button {
          display: flex;
          height: 44px;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
        }
        .unlimited-banner {
          margin-top: 24px;
          background: #e6eff7;
          display: flex;
          padding: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          align-self: stretch;
          .banner-decription {
            margin: 10px;
          }
          .join-unlimited {
            font-weight: 400;
            background: #112b4b;
            width: 128px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .svg-container {
            width: 160px !important;
            height: 44px !important;
            svg {
              width: 100%;
              height: 100%;
            }
            .inner-svg {
              width: 80%;
              height: 80%;
            }
          }
        }
      }
    }
    .enabled-vine-header {
      .aa-Panel {
        background: ${theme.colors.white};
        clip-path: inset(0 -100vmax);
        z-index: 999;
        box-shadow: 0 0 0 100vmax ${theme.colors.white};
        margin: 0;
        height: 100%;
        padding: 0 13px 67px;
        width: 100% !important;
        ${theme.breakpoints.lg} {
          height: auto;
          padding: 0;
        }
      }
      .aa-PanelLayout {
        max-height: 100%;
        flex-direction: column;
        ${theme.breakpoints.lg} {
          max-height: initial;
        }
      }
      .aa-SourceHeader {
        border-bottom: 0;
      }
    }
    .vine-react-app.enabled-vine-header .aa-Panel {
      width: 100% !important;
      ${theme.breakpoints.lg} {
        width: calc(100% + 400px) !important;
      }
    }
  `;

  return globalStyles;
};

const GlobalStyles = () => {
  const theme = useTheme();

  const globalStyles = globalcss(theme);
  return <Global styles={globalStyles} />;
};

export default GlobalStyles;
